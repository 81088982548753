function valuesToKeys(arr) {
  var obj = {};
  for (var i = 0, len = arr.length; i < len; i++) {
    obj[arr[i]] = true;
  }
  return obj;
}


function requestUpdateTimeout(callback) {
  
  requestAnimationFrame(callback);
  //setTimeout(callback,0);
}


/*
page scroll percentage????
or scroll percentage for certain elements on the page ????


trigger:
moved, page-moved (status: scrolling, appearing, disappearing)
show,became visible, page-appeared
hide,became invisible, page-disappeared

page-appeared
page-disappeared
page-moved
page-transitioning-start
page-transitioning
page-transitioning-end

TODO:
- class name definition
- option for min-height auto update !!!!????

TODO:
- option to keep page position while resizing !!!!!
(should ne separate module list, and combine with min-height)

TODO:
- snap to page (separate module)

TODO:
code cleanup

TODO:
- think over the events that have to be emitted, an event that is necessary to reset the positions.
- information when page behjavem visible, and when invisible. when appearing started and disappeard.
- various other effents

TODO - IMPORTANT:
- check support with touch (not continous support)
- iOS <5 does not support position fixed
*/


function PageScroller(container) {
  this.pagesContainer = container;
  this._updateRequested = false;
  this._needsUpdate = {};
  this.scrollTop = 0;
  this.scrollDir = 0;
  this.nextVisPercent = 0;
  this.snapTo = 'none';
  this.visiblePages = [];
  this.pages = [];

  if(!Modernizr.positionfixed) {
    //without position fixed we don't support the page scroller
    return;
  }
  this.setup();



  this.windowResized();
  $(this.windowResized);

}


PageScroller.prototype.setup = function() {
  var pages;

  this.pageRule = createEmptyCssRule('.page-min-height');
  this.pagesPlaceholder = $('<div>');

  $body.append(this.pagesPlaceholder);

  this.pagesContainer.css({
    position: 'fixed'
  });

  this.updatePlaceholder();

  pages = this.pages;

  this.pagesContainer.find('.page').each(function() {
    pages.push(new Page(this, pages[pages.length - 1]));
  });


  //binding listeners to object
  this.windowResized = this.windowResized.bind(this);
  this.windowScrolled = this.windowScrolled.bind(this);
  this.processUpdate = this.processUpdate.bind(this);
  this.hashChange = this.hashChange.bind(this);


  //setup the listeners
  //TODO this should be common for all PageScrollers (if we have multible layers / columns )
  //     depending on which one is active it will receiv this event !!!!
  $(window).on('scroll', this.windowScrolled);
  $(window).on('resize', this.windowResized);
  $(window).on('load', this.windowResized);
  $(window).on('hashchange', this.hashChange);
  
  $('.page-anchor').each(function() {
    var id = $(this).attr('id');
    $(this).attr('id','').prop('id','').attr('id2',id);
    $(this).data('anchor',id);
  });
  
  $(window).on('load', function() {
    if( location.hash ) {
      $(window).trigger('hashchange');
    }
  });
  //if( location.hash ) {
  //  $(window).trigger('hashchange');
  //}
};

PageScroller.prototype.hashChange = function(e) {
  e.stopPropagation(); 
  e.preventDefault(); 
  
  $('.page-anchor, .page-anchor-sub').each(function() {
    var elm = $(this);
    var id = elm.data().anchor;
    
    if( '#'+id == location.hash) {
      
      $('html, body').animate({
        'scrollTop': elm.offset().top
      }, {
        step: function() {
          //console.dir(arguments);
          //$(window).scrollTop(elm.offset().top);
          //this._updateScroll();
        },
        duration: 1000
      });
    }
  });
  
};

PageScroller.prototype.windowResized = function() {
  this.requestUpdate('resize');
};

PageScroller.prototype.windowScrolled = function() {
  this.requestUpdate('scroll');
};

PageScroller.prototype.requestUpdate = function(type) {
  this._needsUpdate[type] = true;
  if (!this._updateRequested) {
    this._updateRequested = true;
    requestUpdateTimeout(this.processUpdate);
  }
};



PageScroller.prototype.processUpdate = function() {

  if (this._needsUpdate.resize) {
    this._updateResize();
  }

  if (this._needsUpdate.scroll) {
    this._updateScroll();
  }


  var scrollTop = $(window).scrollTop();
  var elm, found=false;
  $('.page-anchor').each(function() {
    var top = $(this).closest('.page-anchor-switch').offset().top;
    
    if( scrollTop > top-100 && !found ) {
      //found = true;
      elm = $(this);
    }
  });
  
  if (elm) {
    if (this.lastPage != elm.data().anchor) {
      this.lastPage = elm.data().anchor;
      $(document).trigger(
        jQuery.Event('page-changed', {
          page: this,
          anchor: this.lastPage
        }));
    }
  }



  this._updateRequested = false;
  this._needsUpdate = {};
};

PageScroller.prototype._updateResize = function() {
  var i, len;

  //retriving the ucrrent window dimensions
  this.windowWidth = $(window).innerWidth();
  this.windowHeight = $(window).innerHeight();

  this.scrollBottom = this.scrollTop + this.windowHeight;

  this._updateMinHeight();

  //updated the pages because the dimension changed
  for (i = 0, len = this.pages.length; i < len; i++) {
    this.pages[i].updateInfos();
  }

  this.updatePlaceholder();
};

/**
TODO this should be moved a separate module ????
*/
PageScroller.prototype._updateMinHeight = function() {
  //update the minimum height of the page
  
  this.pageRule.css({
    'min-height': this.windowHeight + 'px'
  });
};


PageScroller.prototype.updatePlaceholder = function() {
  this.pagesOuterHeight = this.pagesContainer.outerHeight();
  this.pagesPlaceholder.outerHeight(this.pagesOuterHeight);
};


PageScroller.prototype.updateDebugInfo = function() {
  var debugStr = '';
  if (this.scrollDir > 0) {
    debugStr = '⬇︎';
  } else if (this.scrollDir < 0) {
    debugStr = '⬆︎';
  } else {
    debugStr = '•';
  }

  debug(debugStr + ' (' + this.visiblePages.join(', ') + ')');
};



$(function() {
  $body = $('body');
  $('.page-container').each(function() {
     window.testScroller = new PageScroller($(this));
  });
 
});
