/*
TODO save the nextVisPercent, ... with the page and not with the Scroller class

*/

PageScroller.prototype._updatePagePercent = function(page) {
  //check if page is moved out of the top
  var diff,
    transitionHeight = Math.min(this.windowHeight, page.height);

  page.transitionHeight = transitionHeight;
  
  page.scrollPercent = (page.offsetTop-this.scrollTop)/page.height;

  if (this.scrollTop > page.offsetTop) {
    var localOffset = page.height - transitionHeight;
    //check if dissapearin
    if (this.scrollTop > page.offsetTop + localOffset) {
      diff = this.scrollTop - (page.offsetTop + localOffset);
      page.setVisiblePercent(diff / transitionHeight, 'top');
    } else {
      page.setVisiblePercent(1, 'none');
    }
  } else {
    diff = this.scrollBottom - page.offsetTop;
    if (diff < transitionHeight) {
      page.setVisiblePercent(diff / transitionHeight, 'bottom');
    } else {
      //TODO this should not happen
    }
  } 
  
  /*
  TOTO trnaisiton end event should be 
  
  */
};


PageScroller.prototype._updateScroll = function() {
  var prevVisibilePages = this.visiblePages;
  
  this._updateScrollDirection();
  this._updatePageContainerPosition();
  
  this.visiblePages = this._getVisiblePages();
  
  
  var _this = this;
  this.visiblePages.forEach(function(page) {
    removeFromArray(prevVisibilePages, page);
    
    page.show();
    
    _this._updatePagePercent(page);
    
    page.scrolled();
  });
  
  prevVisibilePages.forEach(function(page) {
    page.hide();
  });
  
  this.updateDebugInfo();
  
//  this._determinSnapTo();
};


PageScroller.prototype._updateScrollDirection = function() {
  var lastScroll = this.scrollTop;

  this.scrollTop = $(window).scrollTop();
  this.scrollBottom = this.scrollTop + this.windowHeight;

  this.scrollDir = this.scrollTop - lastScroll;
  if (this.scrollDir !== 0) {
    this.scrollDir /= Math.abs(this.scrollDir);
  }
};

PageScroller.prototype._updatePageContainerPosition = function() {
  /*
  chrome vs safari elastic scrolling
  */
  //this.scrollTop = Math.max(this.scrollTop,0);


  //use transformation if supported
  if( Modernizr.csstransforms ) {
    this.pagesContainer.css({
      transform: 'translateY(' + (-this.scrollTop) + 'px)'
    });
  } else {
    this.pagesContainer.css({
      top: (-this.scrollTop)
    });
  }
 
};

PageScroller.prototype._getCurrentActivePage = function() {
  var i, len;

  for (i = 0, len = this.pages.length; i < len; i++) {
    if (this.scrollTop < this.pages[i].offsetBottom) {
      break;
    }
  }
  if (i >= this.pages.length) {
    //TODO this should not happen
    i = this.pages.length - 1;
  }

  return i;
};

/**
TODO we should move this to an external module
*/
PageScroller.prototype._determinSnapTo = function() {
  if (this.nextVisPercent !== 0) {
    if (this.nextVisPercent > 0.5) {
      this.snapToPage = this.currentPage + 1;
      this.snapTo = 'top';
    } else {
      this.snapToPage = this.currentPage;
      this.snapTo = 'bottom';
    }
  } else {
    this.snapTo = 'none';
    this.snapToPage = false;
  }
};

PageScroller.prototype._getVisiblePages = function() {
  var i, len, visiblePages = [];

  for (i = 0, len = this.pages.length; i < len; i++) {
    if (this.scrollTop <= this.pages[i].offsetBottom && this.scrollBottom >= this.pages[i].offsetTop) {
      visiblePages.push(this.pages[i]);
    }
  }

  return visiblePages;
};
