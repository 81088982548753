(function($, undefined) {
  if( !window.Modernizr ) {
    return missing('Modernizr');
  } else if( !window.jQuery ) {
    return missing('jQuery');
  }
  
  var resizeEvent = 'paralax-resize';
  var $body;
  var debugElement = $('.debug');
  
  function debug(text) {
    if( debugElement.length === 0  ) {
      debugElement = $('.debug');
    }
    debugElement.text(text);
  }
  

  function removeFromArray(arr, elem) {
    var index = arr.indexOf(elem);

    if (index > -1) {
        arr.splice(index, 1);
    }
  }
  
  function roundTo(value, decimals) {
    var tmp = Math.pow(10, decimals);
    return Math.round(value * tmp) / tmp;
  }
