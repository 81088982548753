function Page(elem, prev) {
  this.elem = $(elem);
  this.wrapper = $('<div>');
  this.wrapper.addClass('page-wrapper');
  this.elem.before(this.wrapper);
  this.wrapper.append(this.elem);
  this.visible = false;
  this.scrollPercent = 0;
  this.prevPage = prev;
  this.nextPage = null;
  this.status = '';
  
  if( prev ) {
    prev.nextPage = this;
  }
  this.updateInfos();
}

Page.prototype.updateInfos = function() {
  this.offsetTop = Math.round(this.wrapper.position().top);
  this.height = Math.round(this.wrapper.outerHeight());
  this.offsetBottom = this.offsetTop + this.height;
};

Page.prototype.setVisiblePercent = function(percent, border) {
  
  if (!this.visible && (this.scrollPercent > -1 && this.scrollPercent < 1)) {
    this.visible = true;
    this.elem.removeClass('page-invisible').addClass('page-visible');
    this.elem.trigger(
      jQuery.Event('page-appeared', {
        page: this
      })
    );
  }

  
  if (this.visible && (this.scrollPercent <= -1 || this.scrollPercent >= 1)) {
    this.visible = false;
    this.elem.removeClass('page-visible').addClass('page-invisible');
    this.elem.trigger(
      jQuery.Event('page-disappeared', {
        page: this
      })
    );
  }
  
  if( this.transitPercent !== percent ) {
    this.transitPercent = percent;
    this.transitBorder = border;
    if( percent !== 1 && percent !== 0 ) {
      this.elem.trigger(
        jQuery.Event('page-transiting', {
          page: this
        })
      );
    } else {
      this.elem.trigger(
        jQuery.Event('page-transiting-end', {
          page: this
        })
      );
    }
  }
};

Page.prototype.scrolled = function() {
  this.elem.trigger(
    jQuery.Event('page-scroll', {
      page: this
    })
  );
};

Page.prototype.index = function() {
  return this.elem.parent().index();
};


Page.prototype.show = function() {

  this.setVisiblePercent(1, 'none');
  if( !this.visible  ) {
    this.visible = true;
    this.elem.trigger(
      jQuery.Event('page-appeared', {
        page: this
      })
    );
  }
};

Page.prototype.hide = function() {

  this.setVisiblePercent(0, 'none');
  if( this.visible  ) {
    this.visible = false;
    this.elem.trigger(
      jQuery.Event('page-disappeared', {
        page: this
      })
    );
  }
};

Page.prototype.toString = function(status) {
  return '[p:'+this.index()+' '+roundTo(this.transitPercent,4)+' '+roundTo(this.scrollPercent,4)+' ]';
};
